`<template>
  <div
    class="guide-page-container"
    :class="{
      'layout-border': !isGuidePage,
      noBorder: isGuidePage,
    }"
  >
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      v-if="!isGuidePage"
    ></PageButton>
    <div class="guide-page-box">
      <div class="guide-page-content">
        <div class="card-item" v-for="(item, index) in cardList" :key="index">
          <div class="card-up" v-if="index !== 1">
            <div
              class="img-box"
              @mouseenter="handleMouseEnter(index)"
              @mouseout="handleMouseOut"
              @click="handleClickCard(index)"
            >
              <img :src="[isHover && hoverIndex === index  ? item.hoverImg : item.bgImg]" :class="[ scaleimageanimation ? 'scaleToAppear' : 'isHidden',  ]" />
            </div>
          </div>
          <div class="card-dowm" v-if="index !== 1">
            <div class="card-dowm-content-box" v-if="item.isShowAnswer">
              <div class="card-dowm-content">
                <span class="pinyin card-pinyin font-pinyin-large">{{
                  item.pinyin
                }}</span>
                <span class="card-pinyin font-hanzi-large">{{
                  item.hanzi
                }}</span>
                <span class="card-english font-english-large">{{
                  item.english
                }}</span>
              </div>
            </div>
          </div>
          <!-- 第二列反过来 -->
          <div class="card-dowm secondAnswerItem" v-if="index === 1">
            <div
              class="card-dowm-content-box"
              :class="{ redBg: item.bgColorType === 1 }"
              v-if="item.isShowAnswer"
            >
              <div class="card-dowm-content">
                <span class="pinyin font-pinyin-large">{{ item.pinyin }}</span>
                <span class="font-hanzi-large">{{ item.hanzi }}</span>
                <span class="font-english-large">{{ item.english }}</span>
              </div>
            </div>
          </div>
          <div class="card-up secondItem" v-if="index === 1">
            <div
              class="img-box"
              @mouseenter="handleMouseEnter(index)"
              @mouseout="handleMouseOut"
              @click="handleClickCard(index)"
            >
              <img :src="[isHover && hoverIndex === index  ? item.hoverImg : item.bgImg]" :class="[ scaleimageanimation ? 'scaleToAppear' : 'isHidden',  ]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools.js";

export default {
  name: "GuideGame",
  props: {
    cardList: {
      type: Array,
      require: true,
      default: () => {},
    },
    isGuidePage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHover: false,
      hoverIndex: null,
      clickSocketInfo: {},
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      scaleimageanimation: false,
    };
  },
  watch: {
    // 发送
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20056,
          data: { value },
          text: "GuideGame按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  components: {
    PageButton,
  },
  mounted() {
    this.$bus.$on("clickGuide", ({ index }) => {
      this.handleClickCard(index, true);
    });
    this.prepareAppearAnimation();
  },
  beforeDestroy() {
    this.$bus.$off("clickGuide");
  },
  methods: {
    prepareAppearAnimation(){
      var _this = this;
        setTimeout(()=>{
          _this.scaleimageanimation = true;
        }, 300);
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickCard(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          index,
          value: Math.random(),
        };
      }

      this.$emit("showCardItem", index);
      if (!this.isGuidePage) {
        let arr = this.cardList.filter((item) => {
          return item.isShowAnswer;
        });
        if (arr.length === this.cardList.length) {
          playCorrectSound();
          startConfetti();
          this.isLastStep = true;
        } else {
          playCorrectSound(true, false);
        }
      } else {
        playCorrectSound(true, false);
      }
    },
    handleMouseEnter(index) {
      this.isHover = true;
      this.hoverIndex = index;
    },
    handleMouseOut() {
      this.isHover = false;
      this.hoverIndex = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.guide-page-container {
  // width: 100%;
  // height: 100%;
  .guide-page-box {
    width: 100%;
    height: 100%;
    border-radius: 56px;
    background: url("../../../../assets/img/03-Backgrounds/background-practice.svg")
      no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .guide-page-content {
      width: 80%;
      height: 90%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .card-item {
        flex: 1;
        display: flex;
        height: 85%;
        justify-content: space-between;
        flex-direction: column;
        .card-up {
          flex: 4;
          height: 80%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          .img-box {
            width: 80%;
            img {
              width: 100%;
              cursor: pointer;
            }
          }
        }
        .secondItem {
          align-items: flex-end;
        }
        .card-dowm {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          flex: 3;
          .card-dowm-content-box {
            width: 14vw;
            height: 14vw;
            background: #224e96;
            border-radius: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .redBg {
            background: #cd4c3f;
          }
          .card-dowm-content {
            display: flex;
            flex-direction: column;
            span {
              // display:inline-block;
              text-align: center;
              color: #fff;
            }
          }
          // width: 80%;
        }
        .secondAnswerItem {
          align-items: flex-start;
        }
      }
    }
  }
}
.noBorder {
  width: 100%;
  height: 100%;
}
</style>
